// import male from "@/assets/images/role-admin.jpg"
// import female from "@/assets/images/role-headmaster.jpg"
// import smale from "@/assets/images/role-teacher.jpg"
import male from "@/assets/avatar/male.png"
import female from "@/assets/avatar/female.png"
import smale from "@/assets/avatar/smale.png"
import zpw from "@/assets/avatar/zpw.png"
import xjh from "@/assets/avatar/xjh.png"
import tz from "@/assets/avatar/tz.png"
import whb from "@/assets/avatar/whb.png"
import cpx from "@/assets/avatar/cpx.png"
import nmyzc from "@/assets/avatar/nmyzc.png"
import smbdyhhb from "@/assets/avatar/smbdyhhb.png"
import bhyzxdp from "@/assets/avatar/bhyzxdp.png"
import dag from "@/assets/avatar/dag.png"
import aysxza from "@/assets/avatar/aysxza.png"
import btjyk from "@/assets/avatar/btjyk.png"

const names = {
    张鹏伟: zpw,
    徐靖杭: xjh,
    汤众: tz,
    王海斌: whb,
    陈沛鑫: cpx,
    柠檬叶子C: nmyzc,
    森明帮大于黑虎帮: smbdyhhb,
    捕获一只小肚皮: bhyzxdp,
    "Dark And Grey": dag,
    哎呀是小张啊: aysxza,
    比特就业课: btjyk,
}

export default {
    methods: {
        getAvatar({ imgPath, sex, role, nickName }) {
            if (imgPath === "" || imgPath == null) {
                if (role == 100) {
                    console.log(nickName)
                    imgPath = names[nickName]
                    if (!imgPath) {
                        return cpx
                    }
                } else {
                    if (sex == 1) {
                        imgPath = male
                    }
                    if (sex == 2) {
                        imgPath = female
                    }
                    if (sex == 3) {
                        imgPath = smale
                    }
                }
            }
            if (imgPath == null) {
                imgPath = names[nickName]
                if (imgPath) {
                    return imgPath
                } else {
                    imgPath = cpx
                }
            }
            return imgPath
        },
    },
}
