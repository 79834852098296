<template>
    <div id="app">
        <router-view />
        <cms-login></cms-login>
        <cms-bindphone></cms-bindphone>
        <el-backtop :right="20"></el-backtop>
    </div>
</template>
<script>
import cmsLogin from "@/views/login/cms-login"
import cmsBindphone from "@/views/login/cms-bindphone.vue"
export default {
    name: "app",
    components: {
        cmsLogin,
        cmsBindphone
    },
}
</script>
<style lang="scss">

</style>
