export const getOpts = () => {
	return [
		{
			label: "全部",
			value: "0",
		},
		{
			label: "作业",
			nickName: "鹏哥C语言",
			path: "/clanguage",
			value: "1",
			component: "cms-card",
			list: [],
			total: 0,
			pageForm: {
				page: 1,
				limit: 8,
				type: "1",
			},
			width: 1084,
			height: 776,
			row: {
				xs: 1,
				sm: 1,
				md: 2,
				lg: 4,
				xl: 4,
			},
		},
		{
			label: "资料",
			nickName: "课程资料",
			path: "/doc",
			value: "2",
			component: "cms-card",
			list: [],
			total: 0,
			pageForm: {
				page: 1,
				limit: 6,
				type: "2",
			},
			row: {
				xs: 1,
				sm: 1,
				md: 3,
				lg: 3,
				xl: 3,
			},
		},
		{
			label: "就业课",
			nickName: "比特就业课",
			value: "3",
			path: "/biteclass",
			component: "cms-card",
			list: [],
			total: 0,
			pageForm: {
				page: 1,
				limit: 8,
				type: "3",
			},
			width: 1040,
			height: 585,
			row: {
				xs: 1,
				sm: 1,
				md: 2,
				lg: 4,
				xl: 4,
			},
		},
		{
			label: "问答",
			nickName: "有问必答",
			value: "4",
			path: "/qa",
			component: "cms-qa-index-card",
			list: [],
			total: 0,
			pageForm: {
				page: 1,
				limit: 8,
				type: "4",
			},
			width: 1040,
			height: 585,
			row: {
				xs: 1,
				sm: 1,
				md: 2,
				lg: 4,
				xl: 4,
			},
		},
	];
};

export const map2Data = type => {
	const opts = getOpts();
	if (type == 0) {
		opts.shift();
		return opts.reduce((prev,next) => {
			const {value} = next;
			prev[value] = next;
			return prev;
		},{});
	}
	return opts
	.filter(opt => {
		const {value} = opt;
		return value == type;
	})
	.reduce((prev,next) => {
		const {value} = next;
		prev[value] = next;
		return prev;
	},{});
};

export function map2DataList(type) {
	const opts = getOpts();
	if (type == 0) {
		opts.shift();
		return opts;
	}
	return opts.filter(opt => {
		const {value} = opt;
		return value == type;
	});
}
