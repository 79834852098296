import request from "@/utils/request";

// 首页获取menu类别
export function getPathMenus(limit = 10000) {
	return request({
		url: `/web/tmsPath/semiLogin/page`,
		method: "get",
		params: {page: 1,limit: limit,params: JSON.stringify({comStatus: "1",saveStatus: "0"})},
	});
}

// 学习路径详情
export function getPathDetail(id) {
	return request({
		url: `/web/tmsPath/semiLogin/info?id=${id}`,
		method: "get",
		params: {},
	});
}


// 知识点
export function getPathPointList({page,limit,treeId}) {
	return request({
		url: `/web/tmsPathTree/semiLogin/pointList`,
		method: "get",
		params: {page,limit,treeId},
	});
}

// 作业
export function getPathHomeworkList({page,limit,treeId}) {
	return request({
		url: `/web/tmsPathTree/semiLogin/clHomeworkList`,
		method: "get",
		params: {page,limit,treeId},
	});
}

//
export function getPathDocList({page,limit,treeId}) {
	return request({
		url: `/web/tmsPathTree/semiLogin/clInfoList`,
		method: "get",
		params: {page,limit,treeId},
	});
}
