<template>
    <div class="cms-bread">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item
                v-for="(item, index) in mRoutes"
                :class="{ 'last-breadcrumb-item': index == mRoutes.length - 1 }"
                :to="item"
                :key="index"
            >
                {{ item.meta.title }}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
import pathToRegexp from "path-to-regexp"
export default {
    name: "cms-bread",
    data() {
        return {
            mRoutes: [],
        }
    },
    watch: {
        $route() {
            this.getBreadcrumb()
        },
    },
    created() {
        this.getBreadcrumb()
    },
    methods: {
        getBreadcrumb() {
            let matched = this.$route.matched
            this.mRoutes = matched
        },
        pathCompile(path) {
            const { params } = this.$route
            var toPath = pathToRegexp.compile(path)
            return toPath(params)
        },
        handleLink(item) {
            const { redirect, path } = item
            if (redirect) {
                this.$router.push(redirect)
                return
            }
            this.$router.push(this.pathCompile(path))
        },
    },
}
</script>

<style lang="scss">
.cms-bread {
    padding: 16px 0;
    .el-breadcrumb__item {
        .el-breadcrumb__inner {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
        }
    }
    .el-breadcrumb__item:last-child {
        font-size: 14px;
        font-weight: 400;
        color: #2689ff;
    }

    .last-breadcrumb-item {
        .el-breadcrumb__inner {
            font-size: 14px !important;
            font-weight: 400 !important;
            color: #2689ff !important;
        }
    }
}
</style>
