<template>
    <div class="cms-menu">
        <el-menu class="cms-el-menu" :default-active="activeMenu" mode="horizontal" router>
            <cms-menu-item v-for="(item,index) in menus" :key="item.path" :post-index="index" :post-item="item">
            </cms-menu-item>
        </el-menu>
    </div>
</template>

<script>
import cmsMenuItem from "./cms-menu-item.vue"
import { mapGetters } from "vuex"
export default {
    name: "cms-menu",
    components: { cmsMenuItem },
    data() {
        return {
            path: null,
            routers: []
        }
    },
    computed: {
        ...mapGetters(["menus"]),
        activeMenu() {
            const route = this.$route
            const { meta, path, matched, query } = route
            if (meta.reload) {
                const result = this.$router.resolve({
                    path: '/reload',
                    query: { ...query, redirect: path }
                });
                return result.href
            }
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            if (matched[0]) {
                return matched[0].path
            }
            return path
        },
    }
}
</script>
<style lang="scss" scoped>
.cms-menu {
    float: left;
    height: 100%;

    .cms-el-menu {
        border-bottom: none;
        margin-top: 4px;
    }
}
</style>

<style lang="scss">
.el-menu--horizontal {
    &>.cms-menu-item {
        .el-menu-item {
            float: left;
            font-size: 16px;
            height: 60px;
            line-height: 60px;
            margin: 0;
            padding: 0 14px;
            border-bottom: 2px solid transparent;
            color: #909399;
        }

        .el-menu-item.is-active {
            border-bottom: 2px solid #409eff;
            color: #303133;
        }

        .el-submenu.is-active .el-submenu__title {
            border-bottom: 2px solid #409eff;
            color: #303133;
        }

        .el-submenu__title:hover {
            background-color: transparent;
        }

        .el-menu-item:focus,
        .el-menu-item:hover {
            background-color: transparent;
            color: #303133;
        }
    }
}
</style>
