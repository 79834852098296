import hasRole from "./permission/hasRole"
import hasPermi from "./permission/hasPermi"
import hasLogin from "./permission/hasLogin"

const install = function (Vue) {
    Vue.directive("hasRole", hasRole)
    Vue.directive("hasPermi", hasPermi)
    Vue.directive("hasLogin", hasLogin)
}

if (window.Vue) {
    window["hasRole"] = hasRole
    window["hasPermi"] = hasPermi
    window["hasLogin"] = hasLogin
    Vue.use(install) // eslint-disable-line
}

export default install
