import {init,initUrlMap,trackApi,onBury} from "@xmon/bury";
import router from "./router";
import request from "@/utils/request";
import {getToken} from "@/utils/auth";

/**
 * conf
 */
const config = {
	environment: process.env.NODE_ENV,
	dataPointVersion: "v0.0.1",
	version: "1.0.0",
};
const bury = init(config,router);
window.aa = bury;
/**
 * events
 */
initUrlMap([
	{
		path: "/index",
		leave: "load-index-leave-0",
		enter: "load-index-enter-0",
	},
	{
		path: "/clanguage",
		leave: "load-c-leave-0",
		enter: "load-c-enter-0",
	},
	{
		path: "/clanguage/detail/:id",
		leave: "load-c_detail-leave-0",
		enter: "load-c_detail-enter-0",
	},
	{
		path: "/clanguage/homework/:id",
		leave: "load-homework-leave-0",
		enter: "load-homework-enter-0",
	},
	{
		path: "/biteclass",
		leave: "load-cource-leave-0",
		enter: "load-cource-enter-0",
	},
	{
		path: "/biteclass/detail/:id",
		leave: "load-cource_detail-leave-0",
		enter: "load-cource_detail-enter-0",
	},
	{
		path: "/path",
		leave: "load-path-leave-0",
		enter: "load-path-enter-0",
	},
	{
		path: "/doc",
		leave: "load-doc-leave-0",
		enter: "load-doc-enter-0",
	},
	{
		path: "/doc/detail/:id",
		leave: "load-doc_detail-leave-0",
		enter: "load-doc_detail-enter-0",
	},
	{
		path: "/qa",
		leave: "load-qa-leave-0",
		enter: "load-qa-enter-0",
	},
	{
		path: "/qa/detail/:id",
		leave: "load-qa_detail-leave-0",
		enter: "load-qa_detail-enter-0",
	},
	{
		path: "/aboutus",
		leave: "load-aboutus-leave-0",
		enter: "load-aboutus-enter-0",
	},
	{
		path: "/aboutus/more",
		leave: "load-aboutus_detail-leave-0",
		enter: "load-aboutus_detail-enter-0",
	},
	{
		path: "/msg",
		leave: "load-msg-leave-0",
		enter: "load-msg-enter-0",
	},
	{
		path: "/search",
		leave: "load-search-leave-0",
		enter: "load-search-enter-0",
	},
	{
		path: "/info",
		leave: "load-info-leave-0",
		enter: "load-info-enter-0",
	},
]);
trackApi(request);
onBury(value => {
	const buryInfo = value.payload;
	const queries = Object.entries(buryInfo);
	const results = queries.reduce((prev,next) => {
		const [key,value] = next;
		prev[key] = value;
		return prev;
	},{});
	results.token = getToken() || "";
	if (
		results.eventId == "load-c_detail-enter-0" ||
		results.eventId == "load-c_detail-leave-0" ||
		results.eventId == "load-homework-enter-0" ||
		results.eventId == "load-homework-leave-0" ||
		results.eventId == "load-cource_detail-leave-0" ||
		results.eventId == "load-cource_detail-enter-0"
	) {
		const {to,from} = value.extra || {};
		let id = (to && to.params.id) || (from && from.params.id) || 0;
		let rest = (results.eventId + "").split("-");
		rest.splice(3,1);
		if (!id) {
			const pageUrl = results.pageUrl;
			const [first] = (pageUrl + "").split("/").reverse();
			id = first || 0;
		}
		rest.push(id);
		results.eventId = rest.join("-");
	}
	sendMsg(results);
});
const sendMsg = function (datas) {
	return request({
		url: "/event/semiLogin/record/save",
		method: "post",
		data: {...datas},
	}).catch(() => {
	});
};

// bury.spy()
export default bury;
