import { login, logout, getInfo } from "@/api/login"
import { getToken, setToken, removeToken } from "@/utils/auth"
import cmsAvatar from "@/mixins/cms-avatar"

const user = {
    state: {
        token: getToken(),
        userId: "",
        openId: "",
        name: "",
        avatar: "",
        user: {},
        roles: [],
        permissions: [],
        showLogin: false,
        showBindPhone: false,
    },

    mutations: {
        SET_OPENID: (state, openId) => {
            state.openId = openId
        },
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_USERID: (state, id) => {
            state.userId = id
        },
        SET_USER: (state, user) => {
            state.user = user
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        },
        SET_LOGINPAGE: (state, showLogin) => {
            state.showLogin = showLogin
        },
        SET_BINDPHONE: (state, showBindPhone) => {
            state.showBindPhone = showBindPhone
        },
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            const phone = userInfo.phone.trim()
            const code = userInfo.code
            return new Promise((resolve, reject) => {
                login({ phone, code })
                    .then(res => {
                        const { token } = res.data
                        setToken(token)
                        commit("SET_TOKEN", token)
                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        // 获取用户信息
        GetInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getInfo()
                    .then(res => {
                        const user = res.data
                        const { getAvatar } = cmsAvatar.methods
                        user.avatar = getAvatar({ imgPath: user.avatar, sex: user.sex, role: user.role })
                        commit("SET_NAME", user.nickName)
                        if (user.avatar) {
                            commit("SET_AVATAR", user.avatar)
                        }
                        commit("SET_USERID", user.userId)
                        commit("SET_USER", user)
                        resolve(res)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        // 退出系统
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.token)
                    .then(() => {
                        commit("SET_TOKEN", "")
                        commit("SET_ROLES", [])
                        commit("SET_PERMISSIONS", [])
                        removeToken()
                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        // 前端 登出
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit("SET_TOKEN", "")
                removeToken()
                resolve()
            })
        },
    },
}

export default user
