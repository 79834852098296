<template>
    <router-link to="/">
        <div class="cms-logo">logo</div>
    </router-link>
</template>

<script>
export default {
    name: "cms-logo",
}
</script>
<style lang="scss" scoped>
.cms-logo {
    float: left;
    width: 134px;
    height: 33px;
    margin-top: 19px;
    background: url("../../assets/logo.png") no-repeat left top;
    background-size: cover;
    text-indent: -99em;
    overflow: hidden;
    margin-right: 37px;
}
</style>
