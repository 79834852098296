<template>
	<div class="cms-login">
		<el-dialog center destroy-on-close :visible.sync="showLogin" width="750px" :lock-scroll="true"
				   :append-to-body="true" :close-on-click-modal="false" custom-class="cms-el-dialog-login"
				   @open="clearData">
			<div class="cms-login-body">
				<div class="cms-login-left">
					<img src="@/assets/login/login-bg.png"/>
				</div>
				<div class="cns-login-right">
					<div class="cms-login-from">
						<div class="cms-login-form-wrap" v-show="loginType == 'form'">
							<div class="cms-weixin-login-btn">
								<img class="cms-weixin-bg" src="@/assets/login/login-weixin.png"
									 @click="changeLoginType('weixin')"/>
							</div>
							<el-form :model="loginForm" :rules="loginRules" ref="loginForm">
								<el-form-item class="cms-login-form-phone" prop="phone">
									<el-input v-model="loginForm.phone" placeholder="输入手机号">
										<span class="cms-login-phone" slot="prefix"></span>
										<span class="cms-login-getcode" slot="suffix" @click="getPhoneCode">
                                            {{ count == 0 ? "获取验证码" : `${count}s` }}
                                        </span>
									</el-input>
								</el-form-item>
								<el-form-item class="cms-login-form-pwd" prop="code">
									<el-input type="text" v-model="loginForm.code" placeholder="输入验证码"
											  @keyup.enter.native="handleLogin">
										<span class="cms-login-pwd" slot="prefix"></span>
									</el-input>
								</el-form-item>

								<el-form-item class="cms-login-form-submit">
									<el-button :loading="loading" class="cms-login-btn" type="primary"
											   @click.native.prevent="handleLogin">
										登录
									</el-button>
								</el-form-item>
								<div class="cms-login-form-private">
									<el-checkbox v-model="checked">
										注册即代表同意
										<a class="cms-login-form-private-item"
										   @click.stop="gotoPrivate('service')">服务条款</a>
										和
										<a class="cms-login-form-private-item"
										   @click.stop="gotoPrivate('private')">隐私协议</a>
									</el-checkbox>
								</div>
							</el-form>
							<el-divider class="cms-el-divider" content-position="center">第三方账号登录</el-divider>
							<div class="login-others">
                                <span class="login-others-item" @click="changeLoginType('weixin')">
                                    <img src="@/assets/icons/weixin-green.png"/>
                                </span>
							</div>
						</div>
						<div class="cms-login-weixin" v-show="loginType == 'weixin'">
							<div class="cms-weixin-coder-image">
								<div class="cms-weixin-coder-block qr-code" id="qrcode">
								</div>
							</div>
							<div class="cms-login-phone-login">
                                <span class="cms-login-phone-text">
                                    登录遇到问题？
                                    <span class="cms-login-phone-text-item"
										  @click="changeLoginType('weixin')">试试刷新</span>
                                    或者
                                    <span class="cms-login-phone-text-item"
										  @click="changeLoginType('form')">手机号登录</span>
                                </span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {getSysPhoneCode} from "@/api/login";
	import {param} from '@/utils/index';
	import "./wxLogin";

	export default {
		name: "cms-login",
		computed: {
			showLogin: {
				get() {
					return this.$store.getters.showLogin;
				},
				set(val) {
					this.$store.commit("SET_LOGINPAGE",val);
				},
			},
		},
		data() {
			return {
				checked: false,
				loginType: "form",
				loading: false,
				loginForm: {
					phone: "",
					code: "",
				},
				loginRules: {
					phone: [
						{
							required: true,
							pattern: /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/,
							message: "请输入正确的手机号码",
							trigger: "change",
						},
					],
					code: [
						{
							required: true,
							pattern: /^\d{6}$/,
							trigger: "change",
							message: "请输入6位验证码",
						},
					],
				},
				count: 0,
			};
		},
		methods: {
			gotoPrivate(path) {
				const route = this.$router.resolve({
					path: `/${path}`
				});
				window.open(route.href);
			},
			clearData() {
				this.loginForm = {
					phone: "",
					code: "",
				};
				this.count = 0;
				this.loginType = 'form';
				this.checked = false;
				this.loading = false;
			},
			crateWeixinUrl() {
				const data = param({
					redirect: window.location.pathname
				});
				new window.WxLogin({
					self_redirect: false,
					id: "qrcode",
					appid: "wx9f49b3f07ce7182c",
					scope: "snsapi_login",
					redirect_uri: `https://bitejiuyeke.com/reload?${data}`,
					state: Math.ceil(Math.random() * 10000),
					style: "",
					href: window.location.origin + "/weixin-block.css"
				});
			},
			changeLoginType(type) {
				if (!this.checked) {
					this.$modal.msgError("请先同意服务条款和隐私协议");
					return;
				}
				this.loginForm = {
					phone: "",
					code: "",
				};
				this.loginType = type;
				if (type == 'weixin') {
					this.crateWeixinUrl();
				}
			},
			getPhoneCode() {
				if (!this.loginForm.phone) {
					this.$modal.msgError("请输入手机号码");
					return;
				}
				if (!/^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.loginForm.phone)) {
					this.$modal.error("请输入正确的手机号码");
					return;
				}
				getSysPhoneCode({
					phone: this.loginForm.phone,
				}).then(() => {
					this.$modal.msgSuccess("验证码已经发送成功，请查收");
					this.count = 60;
					this.countDown();
				});
			},
			countDown() {
				if (this.count != 0) {
					this.timer = setTimeout(() => {
						this.count--;
						if (this.count < 0) {
							this.count = 0;
						}
						this.countDown();
					},1000);
				}
			},
			handleLogin() {

				this.$refs.loginForm.validate(valid => {
					if (valid) {
						if (!this.checked) {
							this.$modal.msgError("请同意服务条款和隐私协议");
							return;
						}
						this.loading = true;
						this.$store
						.dispatch("Login",this.loginForm)
						.then(() => {
							return this.$store.dispatch("GetInfo");
						})
						.then(() => {
							this.loading = false;
							window.clearTimeout(this.timer);
							this.count = 60;
							this.loginForm = {
								phone: "",
								code: "",
							};
							this.$store.commit("SET_LOGINPAGE",false);
						})
						.catch(() => {
							this.loading = false;
						});
					}
				});
			},
		}
	};
</script>

<style lang="scss" scoped>
	.cms-login-body {
		height: 100%;

		.cms-weixin-login-btn {
			position: relative;
			height: 0;

			.cms-weixin-bg {
				position: absolute;
				width: 50px;
				height: 50px;
				right: -21px;
				top: -44px;
				cursor: pointer;
			}
		}

		.cms-login-left {
			float: left;
			height: 100%;
			width: 332px;

			img {
				display: inline-block;
				width: 100%;
				height: 100%;
			}
		}

		.cns-login-right {
			text-align: center;
			vertical-align: middle;
			height: 100%;
			overflow: hidden;

			&::before,
			&::after {
				display: inline-block;
				vertical-align: middle;
				content: '';
				height: 100%;
			}
		}

		.cms-login-from {
			display: inline-block;
			width: 334px;
			height: 334px;
			background: #FFFFFF;
			border: 1px solid #E5E5E5;
			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
			border-radius: 8px;
			vertical-align: middle;
		}

		.cms-login-form-wrap {
			padding: 50px 28px 29px;
		}

		.cms-login-weixin-text {
			margin-top: 20px;
			font-size: 14px;
			margin-bottom: 8px;
			vertical-align: middle;

			img {
				width: 20px;
				height: 20px;
				margin-right: 8px;
				vertical-align: middle;
			}
		}

		.cms-login-weixin-title {
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 16px;
		}

		.cms-weixin-coder-image {
			text-align: center;

			.cms-weixin-coder-block {
				display: inline-block;
				width: 260px;
				height: 400px;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.cms-login-phone-login {
			position: relative;
			height: 0;

			.cms-login-phone-text {
				position: absolute;
				left: 0px;
				top: -50px;
				font-size: 12px;
				cursor: pointer;
			}

			.cms-login-phone-text-item {
				color: #2982ed;
			}
		}

	}
</style>

<style lang="scss">
	.cms-el-dialog-login {
		.el-dialog__header {
			height: 0;
			padding: 0;
		}

		.el-dialog__body {
			padding: 0 !important;
			height: 450px;
			border-radius: 8px;
		}

		.cms-login-form-phone {
			.cms-login-phone {
				display: inline-block;
				width: 14px;
				margin-top: 12px;
				height: 16px;
				background: url("@/assets/icons/phone.png") no-repeat left center;
				background-size: contain;
			}

			.el-input__inner {
				border-top: none;
				border-left: none;
				border-right: none;
				border-radius: 0;
				padding-right: 96px;
			}

			.cms-login-getcode {
				display: inline-block;
				width: 87px;
				padding-left: 16px;
				font-size: 14px;
				font-weight: 400;
				color: #2982ed;
				line-height: 20px;
				cursor: pointer;
				user-select: none;
				border-left: 1px solid #e4e7ed;
			}
		}

		.cms-login-form-pwd {
			margin-top: 20px;

			.cms-login-pwd {
				display: inline-block;
				margin-top: 12px;
				width: 16px;
				height: 17px;
				background: url("@/assets/icons/code.png") no-repeat left center;
				background-size: contain;
			}

			.el-input__inner {
				border-top: none;
				border-left: none;
				border-right: none;
				border-radius: 0;
			}
		}

		.cms-login-form-submit {
			text-align: center;
			margin-top: 32px;
			margin-bottom: 0;

			.cms-login-btn {
				width: 100%;
			}
		}

		.cms-login-form-private {
			text-align: left;

			.el-checkbox__label {
				font-size: 12px;
				text-align: left;
				margin-top: 8px;
				color: #b2b2b2;
				white-space: normal;
			}

			.cms-login-form-private-item {
				color: #2982ed;
			}
		}

		.cms-el-divider {
			margin-top: 16px;

			.el-divider__text {
				font-size: 12px;
				font-weight: 400;
				color: #999999;
				line-height: 17px;
			}
		}

		.login-others {
			text-align: center;

			.login-others-item {
				margin: 0 4px;
				cursor: pointer;
			}
		}
	}
</style>
