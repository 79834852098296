<template>
    <div class="cms-footer">
        <div class="cms-footer-blank"></div>
        <div class="cms-footer-blocks">
            <div class="cms-footer-blocks-wraper">
                <div class="cms-footer-block-item">
                    <div class="cms-footer-text">
                        比特就业课
                        <div class="cms-footer-dot"></div>
                    </div>
                    <div class="cms-footer-target">
                        专注IT学子就业的精品课程
                    </div>
                </div>
                <div class="cms-footer-block-item cms-footer-info">
                    比特就业课是由西安比特教育科技有限公司推出的线上直播就业课程。共涵盖四大就业课程体系，分别是C++研发课程体系、Java研发课程体系、测试开发课程体系、Web前端课程体系；提供二十多项就业服务；研发了50多款高中低实战项目，致力于让每位IT学子从校园走上工作岗位时，能无缝连接。2021年比特课程全网播放量超过1000万，共有3000多名学员选择了比特就业课正式课程，学员分布在海内外800多所高校中。
                </div>
                <div class="cms-footer-block-item cms-footer-block-contact">
                    <div class="cms-footer-contact-item clearfix">
                        <img class="cms-footer-context-img" src="@/assets/footer-p-weixin.png" />
                        <div class="cms-footer-contact-text">
                            <div class="cms-footer-contact-text-label">关注公众号</div>
                            <div class="cms-footer-contact-text-value">硬核面经分享</div>
                        </div>
                    </div>
                    <div class="cms-footer-contact-item clearfix">
                        <img class="cms-footer-context-img" src="@/assets/footer-p-weixin.png" />
                        <div class="cms-footer-contact-text">
                            <div class="cms-footer-contact-text-label">添加老师微信</div>
                            <div class="cms-footer-contact-text-value">最优咨询服务</div>
                        </div>
                    </div>
                    <div class="cms-footer-tel">
                        <span class="cms-footer-tel-label">客服电话:</span>
                        <span class="cms-footer-tel-num">02989666121</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="cms-copyright">
            西安比特科技教育有限公司版权所有
            <a class="cms-icp" href="https://beian.miit.gov.cn/" target="_blank">陕ICP备18006225号</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "cms-footer",
    data() {
        return {};
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.cms-footer-blank {
    height: 66px;
    background-color: #F5F7FD;
    box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.1);
}

.cms-footer-blocks {
    display: flex;
    align-items: center;
    height: 200px;
    background-color: #2D2D3A;
}

.cms-footer-blocks-wraper {
    display: flex;
    align-items: stretch;
    width: 1200px;
    color: #fff;
    margin: auto;

    .cms-footer-block-item {
        flex: 1;
        padding: 0 22px;
    }

    .cms-footer-text {
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 48px;

        .cms-footer-dot {
            width: 17px;
            height: 4px;
            background: #35D4B7;
        }
    }

    .cms-footer-target {
        margin-top: 32px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FEFEFE;
        line-height: 48px;
    }

    .cms-footer-contact-item {
        float: left;
        margin-top: 26px;
        margin-right: 14px;
        margin-bottom: 16px;

        .cms-footer-context-img {
            float: left;
            width: 77px;
            height: 77px;
            margin-right: 12px;
        }

        .cms-footer-contact-text {
            margin-top: 12px;
            overflow: hidden;
        }

        .cms-footer-contact-text-label {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FEFEFE;
        }

        .cms-footer-contact-text-value {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #9B9EA6;
            margin-top: 16px;
        }
    }

    .cms-footer-block-item+.cms-footer-block-item {
        border-left: 2px solid #41414D;
    }

    .cms-footer-info {
        font-size: 12px;
        font-weight: 200;
        line-height: 20px;
        font-family: PingFang SC;
    }

    .cms-footer-block-contact {
        padding-right: 0;
    }
}


.cms-copyright,
.cms-icp {
    font-size: 16px;
    color: #666A75;
    font-weight: 500;
    text-align: center;
    line-height: 48px;
    background-color: #24242F;
}

.cms-footer-tel {
    font-size: 12px;

    .cms-footer-tel-label {
        margin-right: 6px;
    }
}
</style>
