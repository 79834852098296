<template>
    <div class="cms-large-layout">
        <cms-header></cms-header>
        <div class="ccms-large-body">
            <div class="cms-large-bread"><cms-bread></cms-bread></div>
            <router-view />
        </div>
        <cms-footer></cms-footer>
    </div>
</template>

<script>
import cmsHeader from "./header/cms-header.vue"
import cmsBread from "./cms-bread.vue"
import cmsFooter from "./cms-footer.vue"
export default {
    name: "cms-large-layout",
    components: { cmsHeader, cmsBread, cmsFooter },
}
</script>

<style lang="scss" scoped>
.ccms-large-body {
    min-height: calc(100vh - 80px - 190px);
    margin: auto;
}

.cms-large-bread {
    width: 1440px;
    margin: auto;
}
</style>
