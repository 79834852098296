import Vue from "vue"
import Vuex from "vuex"
import user from "./modules/user"
import menu from "./modules/menu"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    getters: {
        token: state => state.user.token,
        avatar: state => state.user.avatar,
        name: state => state.user.name,
        userId: state => state.user.userId,
        openId: state => state.user.openId,
        showLogin: state => state.user.showLogin,
        showBindPhone: state => state.user.showBindPhone,
        user: state => state.user.user,
        menus: state => state.menu.menus,
        isMenuLoade: state => state.menu.loaded,
    },
    mutations: {},
    actions: {},
    modules: { user, menu },
})
