<template>
	<div class="cms-userinfo">
		<el-badge :value="badgeValue | rightValue" :max="99" class="cms-el-badge">
			<i class="el-icon-bell" @click="gotoMsg" data-bupoint="click-index-message-0"></i>
		</el-badge>
		<el-avatar v-if="!token" icon="el-icon-user-solid" class="cms-el-avatar" v-hasLogin></el-avatar>
		<el-dropdown v-else class="avatar-container right-menu-item hover-effect" trigger="click">
			<!--
				<el-avatar icon="el-icon-s-opportunity" class="cms-el-avatar"></el-avatar>
			-->
			<el-image :src="avatar" class="cms-el-avatar" alt="avatar">
				<cms-error-img post-type="user" :post-sex="user.sex" slot="error"></cms-error-img>
			</el-image>
			<el-dropdown-menu slot="dropdown">
				<router-link to="/info">
					<el-dropdown-item data-bupoint="click-index-info-0">个人中心</el-dropdown-item>
				</router-link>
				<el-dropdown-item @click.native="logoutHanlder">
					<span>退出登录</span>
				</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";
	import {getToken} from "@/utils/auth";
	import {countUnReadMsg} from "@/api/msg";
	import cmsErrorImg from "@/components/cms-error-img/cms-error-img.vue";

	export default {
		name: "cms-userinfo",
		components: {cmsErrorImg},
		computed: {
			...mapGetters(["avatar","token","user"]),
		},
		data() {
			return {badgeValue: ""};
		},
		watch: {
			token() {
				if (this.token === "" || this.token == null) {
					window.clearTimeout(this.timer);
				} else {
					if (this.timer) {
						window.clearTimeout(this.timer);
					}
					this.startGetMsg();
				}
			},
		},
		created() {
			if (this.token || getToken()) {
				this.getMSgData();
				this.startGetMsg();
			} else {
				window.clearTimeout(this.timer);
			}
		},
		beforeDestroy() {
			window.clearTimeout(this.timer);
		},
		methods: {
			gotoMsg() {
				this.$router.push("/msg");
			},
			logoutHanlder() {
				this.$store
				.dispatch("LogOut")
				.then(() => {
					this.$router.push("/");
				})
				.then(() => {
					this.$modal.msgSuccess("退出登录成功");
				});
			},
			getMSgData() {
				return countUnReadMsg().then(resp => {
					this.badgeValue = resp.data;
				});
			},
			startGetMsg() {
				this.timer = window.setTimeout(() => {
					this.getMSgData().then(() => {
						this.timer = this.startGetMsg();
					});
				},30000);
				return this.timer;
			},
		},
	};
</script>
<style lang="scss" scoped>
	.cms-userinfo {
		float: left;
		margin-top: 20px;
		width: 100px;
		vertical-align: middle;
	}

	.cms-el-badge {
		font-size: 30px;
		margin-right: 24px;
		vertical-align: top;
		cursor: pointer;
	}

	.cms-el-avatar {
		width: 40px;
		height: 40px;
		border-radius: 20px;
		cursor: pointer;
	}
</style>
