<template>
	<div class="cms-menu-item">
		<el-submenu v-if="postItem.children&&postItem.children.length" :index="resolvePath(postItem)">
			<template slot="title">{{ postItem.meta.title }}</template>
			<el-menu-item v-for="(item,index) in postItem.children" :index="resolvePath(item)" :key="item.path+index"
						  :data-bupoint="`click-index-${item.id}-0`">
                <span v-if="item.meta.icon" class="cms-menu-icon">
                    <img :src="item.meta.icon"/>
                </span>
				{{ item.meta.title }}
			</el-menu-item>
		</el-submenu>
		<el-menu-item v-else :index="postItem.path" :key="postItem.path" :data-bupoint="`click-index-${postIndex}-0`">
			{{ postItem.meta.title }} <img v-if="postItem.isNew" class="cms-menu-item-new"
										   src="@/assets/doc/layout-menu-new-red.png"/>
		</el-menu-item>
	</div>
</template>

<script>
	export default {
		name: "cms-menu-item",
		data() {
			return {};
		},
		props: {
			postItem: {
				type: Object,
				default() {
					return {};
				}
			},
			postIndex: {
				type: Number,
				default: 0,
			}
		},
		methods: {
			resolvePath(item) {
				const {path,query,redirect} = item;
				let route = null;
				if (path == '/reload' && redirect) {
					route = this.$router.resolve({
						path: path,
						query: {...query,redirect}
					});
				} else {
					route = this.$router.resolve({
						path: path ? `${this.postItem.path}/${path}` : `${this.postItem.path}`,
						query: query
					});
				}
				return route.href;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.cms-menu-item {
		float: left;
		position: relative;
	}

	.cms-menu-icon {
		display: inline-block;
		width: 20px;
		height: 20px;
		margin-right: 6px;
		vertical-align: top;
		font-size: 0;

		img {
			width: 100%;
			height: 100%;
			vertical-align: middle;
		}

	}

	.cms-menu-item-new {
		position: absolute;
		width: 36px;
		height: 16.8px;
		margin-top: 5px;
	}
</style>

<style lang="scss">
	.cms-menu-item {
		& > .el-submenu {
			.el-submenu__title {
				font-size: 16px;
				height: 60px;
				line-height: 60px;
				margin: 0;
				padding: 0 14px;
				border-bottom: 2px solid transparent;
				color: #909399;
			}

			.el-submenu__icon-arrow {
				display: none;
			}
		}
	}
</style>
