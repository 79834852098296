<template>
    <div class="cms-doc-layout">
        <cms-header></cms-header>
        <div class="cms-doc-body">
            <div class="cms-doc-bread"><cms-bread></cms-bread></div>
            <router-view />
        </div>
        <cms-footer></cms-footer>
    </div>
</template>

<script>
import cmsHeader from "./header/cms-header.vue"
import cmsBread from "./cms-bread.vue"
import cmsFooter from "./cms-footer.vue"
export default {
    name: "cms-doc-layout",
    components: { cmsHeader, cmsBread, cmsFooter },
}
</script>

<style lang="scss" scoped>
.cms-doc-body {
    min-height: calc(100vh - 80px - 190px);
    margin: auto;
}
.cms-doc-bread{
    width: 1200px;
    margin: auto;
}
</style>
