<template>
	<div class="cms-dash-search">
		<el-input placeholder="请输入内容" v-model="search" class="cms-el-input" @keyup.enter.native="serarchByKeyword"
				  maxlength="60">
			<el-select class="cms-dash-search-select" v-model="select" slot="prefix" placeholder="请选择">
				<el-option v-for="item in opts" :label="item.label" :value="item.value" :key="item.value"></el-option>
			</el-select>
			<el-button slot="suffix" icon="el-icon-search" @click="serarchByKeyword"></el-button>
		</el-input>
	</div>
</template>

<script>
	import {getOpts} from "@/layouts/header/search-util";
	import {tracked} from "@xmon/bury";

	export default {
		name: "cms-search",
		data() {
			const {path,query} = this.$route;
			return {
				search: query.keyworkds || "",
				select: query.type + "" || "0",
				isSkip: path == "/search",
				opts: getOpts(),
			};
		},
		watch: {
			$route() {
				!this.isSkip && this.getSelected();
			},
		},
		created() {
			!this.isSkip && this.getSelected();
		},
		methods: {
			getSelected() {
				const route = this.$route;
				const {meta,path,matched} = route;
				let temp = meta.activeMenu || (matched[0] && matched[0].path) || path;
				const results = this.opts.filter(item => item.path === temp);
				if (results && results[0]) {
					this.select = results[0].value;
				} else {
					this.select = "0";
				}
			},
			serarchByKeyword() {
				tracked("search");
				if (this.isSkip) {
					this.$router.push({
						path: "/search",
						query: {
							keyworkds: this.search,
							type: this.select,
						},
					});
					this.$nextTick(() => {
						this.$emit("search",{keyworkds: this.search,type: this.select});
					});
					return;
				}
				const path = this.$router.resolve({
					path: "/search",
					query: {
						keyworkds: this.search,
						type: this.select,
					},
				});
				window.open(path.href);
			},
		},
	};
</script>
<style lang="scss">
	.cms-dash-search {
		float: left;
		margin-top: 20px;
		margin-right: 15px;

		.cms-el-input {
			width: 240px;
		}
	}

	.cms-el-input {
		&.el-input > .el-input__inner {
			padding-left: 90px;
			padding-right: 50px;
			border-radius: 20px;
		}

		& > .el-input__prefix {
			width: 70px;
			left: 12px;

			&::after {
				content: "";
				margin: 4px 0;
				border-left: 1px solid #dee6e9;
			}

			.cms-dash-search-select {
				.el-input__inner {
					padding: 0;
					background-color: transparent;
					border: none;
				}
			}
		}

		& > .el-input__suffix {
			.el-button {
				background: transparent;
				border: none;
			}
		}
	}
</style>
