<template>
    <div class="cms-bread-layout">
        <cms-header></cms-header>
        <div class="cms-bread-body">
            <cms-bread></cms-bread>
            <router-view />
        </div>
        <cms-footer></cms-footer>
    </div>
</template>

<script>
import cmsHeader from "./header/cms-header.vue"
import cmsBread from "./cms-bread.vue"
import cmsFooter from "./cms-footer.vue"
export default {
    name: "cms-bread-layout",
    components: { cmsHeader, cmsBread, cmsFooter },
}
</script>

<style lang="scss" scoped>
.cms-bread-body {
    width: 1200px;
    min-height: calc(100vh - 80px - 190px);
    margin: auto;
}
</style>
