import { routes } from "@/router"
import { getPathMenus } from "@/api/path"
import utils from "../mUtils"

// const utils = {
//     findItemByPath(p, data, list, result) {
//         for (let i = 0; i < list.length; i++) {
//             if (result == true) {
//                 return
//             }
//             const { children = [], path } = list[i]
//             if (path == p) {
//                 list[i].children = [...children, ...data]
//                 result = true
//             }
//             if (children && children.length) {
//                 utils.findItemByPath(p, data, children, result)
//             }
//         }
//     },
//     getMenus(routes) {
//         const results = getMenuItems(routes, {})
//         return results
//         function getMenuItems(list, parent) {
//             return list
//                 .map(item => {
//                     const { path, children, hidden } = item
//                     if (hidden) {
//                         return null
//                     }
//                     if (children && children.length) {
//                         item.children = getMenuItems(children, item).filter(item => item)
//                     }
//                     if (path == null || path === "" || path == parent.redirect) {
//                         return null
//                     }
//                     return item
//                 })
//                 .filter(item => item)
//         }
//     },
//     mapPathMenus(list) {
//         return list.map(item => {
//             return createMenu(item)
//         })
//         function createMenu(item) {
//             const { direct } = item
//             let result = null
//             if (direct == 1) {
//                 result = {
//                     path: "/reload",
//                     query: { key: "c" },
//                     redirect: "/path",
//                     direct,
//                     meta: { title: "C语言学习路径", icon: c, activeMenu: "/path" },
//                 }
//             }
//             if (direct == 2) {
//                 result = {
//                     path: "/reload",
//                     query: { key: "cplus" },
//                     redirect: "/path",
//                     direct,
//                     meta: { title: "C++学习路径", icon: cplus, activeMenu: "/path" },
//                 }
//             }
//             if (direct == 3) {
//                 result = {
//                     path: "/reload",
//                     query: { key: "java" },
//                     redirect: "/path",
//                     direct,
//                     meta: { title: "JAVA学习学习路径", icon: java, activeMenu: "/path" },
//                 }
//             }
//             if (direct == 4) {
//                 result = {
//                     path: "/reload",
//                     query: { key: "production" },
//                     redirect: "/path",
//                     direct,
//                     meta: { title: "数据结构学习路径", icon: production, activeMenu: "/path" },
//                 }
//             }
//             if (direct == 7) {
//                 result = {
//                     path: "/reload",
//                     query: { key: "linux" },
//                     redirect: "/path",
//                     direct,
//                     meta: { title: "Linux学习路径", icon: linux, activeMenu: "/path" },
//                 }
//             }
//             if (direct == 8) {
//                 result = {
//                     path: "/reload",
//                     query: { key: "test" },
//                     redirect: "/path",
//                     direct,
//                     meta: { title: "测试开发学习路径", icon: test, activeMenu: "/path" },
//                 }
//             }
//             if (direct == 9) {
//                 result = {
//                     path: "/reload",
//                     query: { key: "front" },
//                     redirect: "/path",
//                     direct,
//                     meta: { title: "前端开发学习路径", icon: front, activeMenu: "/path" },
//                 }
//             }
//             return result
//         }
//     },
// }

const menu = {
    state: {
        menus: [],
        bread: routes,
        loaded: "init",
    },
    mutations: {
        ADD_MENUS: (state, { parentPath, datas }) => {
            // const { menus } = state
            const menus = utils.getMenus(routes)
            let result = false
            utils.findItemByPath(parentPath, datas, menus, result)
            result = false
            // utils.findItemByPath("/aboutus", utils.mapAboutMenus(), menus, result)
            state.menus = menus
        },
        SET_LOADED: (state, param) => {
            state.loaded = param
        },
    },
    actions: {
        GET_Path({ commit }) {
            commit("SET_LOADED", "loading")
            return new Promise((resolve, reject) => {
                return getPathMenus()
                    .then(resp => {
                        const { records } = resp.data
                        const result = utils.mapPathMenus(records)
                        commit("ADD_MENUS", { datas: result, parentPath: "/path" })
                        commit("SET_LOADED", "loaded")
                        resolve(result)
                    })
                    .catch(() => {
                        commit("SET_LOADED", "unloaded")
                        reject([])
                    })
            })
        },
    },
}

export default menu
