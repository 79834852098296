<template>
    <div class="cms-bindphone">
        <el-dialog center destroy-on-close :visible.sync="showBindPhone" width="360px" :lock-scroll="true"
            :append-to-body="true" :close-on-click-modal="false" custom-class="cms-bindphone" @closed="reloadPage">
            <h1 class="cms-bindphone-title">
                绑定手机号
            </h1>
            <el-form :model="phoneForm" :rules="phoneRules" ref="phoneForm">
                <el-form-item class="cms-phone-form-phone" prop="phone">
                    <el-input v-model="phoneForm.phone" placeholder="输入手机号">
                        <span class="cms-phone-phone" slot="prefix"></span>
                        <span class="cms-phone-getcode" slot="suffix" @click="getPhoneCode">
                            {{ count == 0 ? "获取验证码" : `${count}s` }}
                        </span>
                    </el-input>
                </el-form-item>
                <el-form-item class="cms-phone-form-pwd" prop="code">
                    <el-input type="text" v-model="phoneForm.code" placeholder="输入验证码"
                        @keyup.enter.native="handleLogin">
                        <span class="cms-phone-pwd" slot="prefix"></span>
                    </el-input>
                </el-form-item>
                <el-form-item class="cms-phone-form-submit">
                    <el-button :loading="loading" class="cms-phone-btn" type="primary"
                        @click.native.prevent="handleLogin">
                        绑定
                    </el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { getSysPhoneCode, bindPhone } from "@/api/login"
import { setToken } from "@/utils/auth"
export default {
    name: "cms-bindphone",
    computed: {
        showBindPhone: {
            get() {
                return this.$store.getters.showBindPhone
            },
            set(val) {
                this.$store.commit("SET_BINDPHONE", val)
            },
        },
    },
    data() {
        return {
            loading: false,
            phoneForm: {
                phone: "",
                code: ""
            },
            phoneRules: {
                phone: [
                    {
                        required: true,
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: "请输入正确的手机号码",
                        trigger: "change",
                    },
                ],
                code: [
                    {
                        required: true,
                        pattern: /^\d{6}$/,
                        trigger: "change",
                        message: "请输入6位验证码",
                    },
                ],
            },
            count: 0,
        }
    },
    methods: {
        reloadPage() {
            const { query } = this.$route
            const { redirect, ...rest } = query || {};
            this.$router.replace({
                path: redirect,
                query: { ...rest }
            });
        },
        handleLogin() {
            const { query } = this.$route
            const { redirect, code, ...rest } = query || {};
            if (redirect && code) {
                console.log('bindphone------>>>>>', query);
                this.$refs.phoneForm.validate((valid) => {
                    if (valid) {
                        const data = { ...this.phoneForm, openId: this.$store.getters.openId };
                        console.log('绑定手机号=------>>>>', data)
                        return bindPhone(data).then((resp) => {
                            const { token } = resp.data;
                            console.log('绑定手机号接口返回=------>>>>', resp.data)
                            this.$store.commit("SET_TOKEN", token)
                            setToken(token)
                            this.$router.replace({
                                path: redirect,
                                query: { ...rest }
                            });
                            this.showBindPhone = false
                        }).catch((err) => {
                            this.$modal.msgError(err.msg)
                        })
                    }
                });
            }
        },
        getPhoneCode() {
            if (!this.phoneForm.phone) {
                this.$modal.msgError("请输入手机号码")
                return
            }
            if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.phoneForm.phone)) {
                this.$modal.error("请输入正确的手机号码")
                return
            }
            getSysPhoneCode({
                phone: this.phoneForm.phone,
            }).then(() => {
                this.$modal.msgSuccess("验证码已经发送成功，请查收")
                this.count = 60
                this.countDown()
            })
        }
    }
}
</script>


<style lang="scss">
.cms-bindphone {
    .cms-bindphone-title {
        font-size: 24px;
        margin-bottom: 16px;
        text-align: center;
    }

    .cms-phone-form-phone {
        .cms-phone-phone {
            display: inline-block;
            width: 14px;
            margin-top: 12px;
            height: 16px;
            background: url("@/assets/icons/phone.png") no-repeat left center;
            background-size: contain;
        }

        .el-input__inner {
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0;
            padding-right: 96px;
        }

        .cms-phone-getcode {
            display: inline-block;
            width: 87px;
            padding-left: 16px;
            font-size: 14px;
            font-weight: 400;
            color: #2982ed;
            line-height: 20px;
            cursor: pointer;
            user-select: none;
            border-left: 1px solid #e4e7ed;
        }
    }

    .cms-phone-form-pwd {
        margin-top: 20px;

        .cms-phone-pwd {
            display: inline-block;
            margin-top: 12px;
            width: 16px;
            height: 17px;
            background: url("@/assets/icons/code.png") no-repeat left center;
            background-size: contain;
        }

        .el-input__inner {
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0;
        }
    }

    .cms-phone-form-submit {
        text-align: center;
        margin-top: 32px;
        margin-bottom: 0;

        .cms-phone-btn {
            width: 100%;
        }
    }
}
</style>