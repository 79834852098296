import c from "@/assets/doc/path-c.png";
import cplus from "@/assets/doc/path-cplus.png";
import java from "@/assets/doc/path-java.png";
// import production from "@/assets/doc/path-prod.png";
import linux from "@/assets/doc/path-linux.png";
import mysql from "@/assets/doc/path-mysql.png";
import network from "@/assets/doc/path-network.png";
import dsa from "@/assets/doc/path-dsa.png";
import think from "@/assets/doc/path-think.png";
import test from "@/assets/doc/path-test.png";
import front from "@/assets/doc/path-production.png";
import cBtn from "@/assets/doc/path-btn-c.png";
import javaBtn from "@/assets/doc/path-btn-java.png";
import testBtn from "@/assets/doc/path-btn-test.png";

const utils = {
	findItemByPath(p,data,list,result) {
		for (let i = 0; i < list.length; i++) {
			if (result == true) {
				return;
			}
			const {children = [],path} = list[i];
			if (path == p) {
				list[i].children = [...children,...data];
				result = true;
			}
			if (children && children.length) {
				utils.findItemByPath(p,data,children,result);
			}
		}
	},
	getMenus(routes) {
		const results = getMenuItems(routes,{});
		return results;

		function getMenuItems(list,parent) {
			return list
			.map(item => {
				const {path,children,hidden} = item;
				if (hidden) {
					return null;
				}
				if (children && children.length) {
					item.children = getMenuItems(children,item).filter(item => item);
				}
				if (path == null || path === "" || path == parent.redirect) {
					return null;
				}
				return item;
			})
			.filter(item => item);
		}
	},
	mapPathMenus(list) {
		return list.map(item => {
			return createMenu(item);
		}).filter((item) => item);

		function createMenu(item) {
			const {direct,id,indexPic} = item;
			let result = null;
			if (direct == 1) {
				result = {
					path: "/reload",
					query: {key: "c"},
					redirect: "/path",
					direct,
					id,
					indexPic,
					meta: {title: "C语言学习路径",icon: c,btn: cBtn,activeMenu: "/path"},
				};
			}
			if (direct == 2) {
				result = {
					path: "/reload",
					query: {key: "cplus"},
					redirect: "/path",
					direct,
					id,
					indexPic,
					meta: {title: "C++学习路径",icon: cplus,btn: cBtn,activeMenu: "/path"},
				};
			}
			if (direct == 3) {
				result = {
					path: "/reload",
					query: {key: "java"},
					redirect: "/path",
					direct,
					id,
					indexPic,
					meta: {title: "JAVA学习路径",icon: java,btn: javaBtn,activeMenu: "/path"},
				};
			}
			if (direct == 4) {
				result = {
					path: "/reload",
					query: {key: "dsh"},
					redirect: "/path",
					direct,
					id,
					indexPic,
					meta: {title: "数据结构学习路径",icon: dsa,btn: dsa,activeMenu: "/path"},
				};
			}
			if (direct == 7) {
				result = {
					path: "/reload",
					query: {key: "linux"},
					redirect: "/path",
					direct,
					id,
					indexPic,
					meta: {title: "Linux学习路径",icon: linux,btn: testBtn,activeMenu: "/path"},
				};
			}
			if (direct == 8) {
				result = {
					path: "/reload",
					query: {key: "test"},
					redirect: "/path",
					direct,
					id,
					indexPic,
					meta: {title: "测试开发学习路径",icon: test,btn: testBtn,activeMenu: "/path"},
				};
			}
			if (direct == 9) {
				result = {
					path: "/reload",
					query: {key: "front"},
					redirect: "/path",
					direct,
					id,
					indexPic,
					meta: {title: "前端开发学习路径",icon: front,btn: testBtn,activeMenu: "/path"},
				};
			}
			if (direct == 11) {
				result = {
					path: "/reload",
					query: {key: "mysql"},
					redirect: "/path",
					direct,
					id,
					indexPic,
					meta: {title: "MySQL学习路径",icon: mysql,btn: mysql,activeMenu: "/path"},
				};
			}
			if (direct == 12) {
				result = {
					path: "/reload",
					query: {key: "network"},
					redirect: "/path",
					direct,
					id,
					indexPic,
					meta: {title: "网络原理学习路径",icon: network,btn: network,activeMenu: "/path"},
				};
			}
			if (direct == 13) {
				result = {
					path: "/reload",
					query: {key: "os"},
					redirect: "/path",
					direct,
					id,
					indexPic,
					meta: {title: "操作系统学习路径",icon: think,btn: think,activeMenu: "/path"},
				};
			}
			return result;
		}
	},
	mapAboutMenus() {
		return [
			{
				path: "#1",
				meta: {title: "比特简介",activeMenu: "/aboutus"},
			},
			{
				path: "#2",
				meta: {title: "服务内容",activeMenu: "/aboutus"},
			},
			{
				path: "#3",
				meta: {title: "发展历史",activeMenu: "/aboutus"},
			},
			{
				path: "#4",
				meta: {title: "就业情况",activeMenu: "/aboutus"},
			},
		];
	},
};

export default utils;
