<script>
import { sendWeixinCode } from "@/api/login"
import { setToken } from "@/utils/auth"
export default {
    name: "cms-reload",
    created() {
        const { query } = this.$route
        console.log('cms-reload-------------->>>>', query);
        const { redirect, code, ...rest } = query || {};
        if (redirect && code) {
            return this.getWweixinCode(query);
        }
        this.$router.replace({
            path: redirect,
            query: { ...rest }
        });
    },
    render: function (h) {
        return h() // avoid warning message
    },
    methods: {
        getPath(redirect) {
            const index = redirect.indexOf('=');
            const path = redirect.slice(index + 1);
            console.log('cms-reload-path----->>>', path);
            if (path && path !== '') {
                return path;
            } else {
                return "/index"
            }
        },
        getWweixinCode(query) {
            const { redirect, code, ...rest } = query || {};
            return sendWeixinCode(code).then((resp) => {
                console.log('get-openId------>>>>>', resp.data);
                const { token, needBindPhone, openId } = resp.data;
                if (needBindPhone) {
                    this.$store.commit("SET_OPENID", openId)
                    this.$store.commit("SET_BINDPHONE", true)
                } else {
                    this.$store.commit("SET_TOKEN", token)
                    setToken(token)
                    this.$router.replace({
                        path: this.getPath(redirect),
                        query: { ...rest }
                    });
                }
            }).catch((err) => {
                this.$modal.msgError(err.msg)
                this.$store.commit("SET_LOGINPAGE", false)
                this.$router.replace({
                    path: this.getPath(redirect),
                    query: { ...rest }
                });
            })
        }
    }
}
</script>
