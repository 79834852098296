/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "element-ui/lib/theme-chalk/index.css";
import "@/themes/index.scss";
import plugins from "@/plugins";
import directive from "@/directive";
import {filterXSS,escapeAttrValue} from "xss";
import "./config.js";

Vue.use(ElementUI);
Vue.use(plugins);
Vue.use(directive);
Vue.filter("autoImg",function (value) {
	if (!value) return "";
	let text = value.replace(/\<img/gi,'<img style="max-width:100%;height:auto"');
	text = filterXSS(text,{
		stripIgnoreTagBody: ["script"],
		onTag(tag,html) {
			if (tag == "script") {
				return escapeAttrValue(html);
			}
			return html;
		},
		// onTagAttr: function (tag, name, value, isWhiteAttr) {
		//     console.log(tag, name, value, isWhiteAttr)
		//     if (name == "style ") {
		//         debugger
		//         return `${name}: ${value}`
		//     }
		//     return `${name} = ${escapeAttrValue(value)}`
		// },
	});
	return text;
});
Vue.filter("rightValue",function (value) {
	if (!value || value === "") return "";
	return value;
});

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount("#app");
