<template>
    <div class="cms-error-img">
        <img v-if="postType == 'banner'" :src="bannerEmpty" alt="加载失败" />
        <img v-else-if="postType == 'clanguage'" :src="cEmpty" alt="加载失败" />
        <img v-else-if="postType == 'card'" :src="cardEmpty" alt="加载失败" />
        <img v-else-if="postType == 'user'" :src="avatar" alt="加载失败" />
        <img v-else :src="err" alt="加载失败" />
    </div>
</template>

<script>
import err from "@/assets/images/error.jpeg"
import Migrating from "element-ui/src/mixins/migrating"
import cEmpty from "@/assets/banner-empty.jpg"
import bannerEmpty from "@/assets/banner-empty.jpg"
import cardEmpty from "@/assets/card-empty.jpg"
import avatar from "@/assets/images/role-student.jpg"
export default {
    name: "cms-error-img",
    mixins: [Migrating],
    props: {
        postType: {
            type: String,
            default: "banner" | "clanguage" | "card",
        },
    },
    data() {
        return { cEmpty, bannerEmpty, cardEmpty, avatar, err }
    },
}
</script>

<style lang="scss" scoped>
.cms-error-img {
    display: inline-block;
    height: 100%;
    width: 100%;

    img {
        width: 100%;
        height: 100%;
    }
}
</style>
