import request from "@/utils/request"

// 登录方法
export function login({ phone, code }) {
    return request({
        url: "/login",
        method: "post",
        data: { phone, code },
    })
}

// 注册方法
export function register(data) {
    return request({
        url: "/register",
        headers: {
            isToken: false,
        },
        method: "post",
        data: data,
    })
}

// 获取用户详细信息
export function getInfo() {
    return request({
        url: "/web/user/detail",
        method: "get",
    })
}

// 退出方法
export function logout() {
    return request({
        url: "/logout",
        method: "post",
    })
}

// 获取验证码
export function getSysPhoneCode({ phone }) {
    return request({
        url: "/sendCode",
        params: { phone },
        method: "get",
        timeout: 20000,
    })
}
export function uploadAvatar(data) {
    return request({
        url: "/oss/upload/file",
        method: "post",
        data: data,
    })
}
// 获取字典值
export function getDictType(dictType) {
    return request({
        url: `/system/dict/data/dictType/${dictType}`,
        headers: {
            baseURL: "/upms",
        },
        method: "get",
    })
}

export function updateUserInfo(data) {
    return request({
        url: "/web/user/update",
        method: "post",
        data: data,
    })
}

// 发送微信的code给后端
export function sendWeixinCode(code) {
    return request({
        url: "/wechat/login",
        method: "post",
        data: { code },
    })
}

// 微信登录绑定手机号
export function bindPhone({ phone, code, openId }) {
    return request({
        url: "/web/user/semiLogin/bind/phone",
        method: "post",
        data: { code, phone, openId },
    })
}
