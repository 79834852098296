<template>
    <div class="cms-header">
        <div class="cms-header-inner">
            <cms-logo></cms-logo>
            <cms-menu></cms-menu>
            <div class="cms-header-right">
                <cms-search @search="searchMethod"></cms-search>
                <cms-userinfo></cms-userinfo>
            </div>
        </div>
    </div>
</template>
<script>
import cmsLogo from "./cms-logo.vue"
import cmsUserinfo from "./cms-userinfo.vue"
import cmsMenu from "./cms-menu"
import cmsSearch from "./cms-search"
export default {
    name: "cms-header",
    components: { cmsLogo, cmsUserinfo, cmsMenu, cmsSearch },
    props: {
        searchMethod: {
            type: Function,
            default: function () { },
        },
    },
    data() {
        return {
            activeIndex: "1",
        }
    },
}
</script>
<style lang="scss" scoped>
.cms-header {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px rgba(7, 35, 68, 0.15);

    .cms-header-inner {
        width: 1200px;
        height: 80px;
        margin: auto;
        background-color: #fff;
        overflow: hidden;
    }

    .cms-header-right {
        float: right;
        height: 100%;
    }
}
</style>
