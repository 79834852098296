import request from "@/utils/request"

// 获取消息列表
export function getMsgList({ page = 1, limit = 10, messageType = 1 }) {
    return request({
        url: `/web/message/list`,
        method: "get",
        params: {
            page,
            limit,
            messageType,
        },
    })
}

// 设置消息已读
export function readMsgByIds({ messageIds }) {
    return request({
        url: "/web/message/read",
        method: "post",
        data: { messageIds },
    })
}

// 删除消息
export function removeMsgByIds({ messageIds }) {
    return request({
        url: "/web/message/delete",
        method: "post",
        data: { messageIds },
    })
}

// 统计消息
export function countMsg() {
    return request({
        url: "/web/message/count",
        method: "get",
    })
}

// 获取消息总数
export function countUnReadMsg() {
    return request({
        url: "/web/message/unread/count",
        method: "get",
    })
}

// 一键清空
export function clearAllMsg(messageType) {
    return request({
        url: "/web/message/delete/all",
        method: "post",
        data: { messageType },
    })
}
